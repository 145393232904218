exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-accessibility-js": () => import("./../../../src/pages/accessibility.js" /* webpackChunkName: "component---src-pages-accessibility-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-games-js": () => import("./../../../src/pages/games.js" /* webpackChunkName: "component---src-pages-games-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-press-js": () => import("./../../../src/pages/press.js" /* webpackChunkName: "component---src-pages-press-js" */),
  "component---src-pages-privacy-blinnk-js": () => import("./../../../src/pages/privacy-blinnk.js" /* webpackChunkName: "component---src-pages-privacy-blinnk-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-updates-all-js": () => import("./../../../src/pages/updates/all.js" /* webpackChunkName: "component---src-pages-updates-all-js" */),
  "component---src-pages-updates-js": () => import("./../../../src/pages/updates.js" /* webpackChunkName: "component---src-pages-updates-js" */),
  "component---src-templates-update-js": () => import("./../../../src/templates/update.js" /* webpackChunkName: "component---src-templates-update-js" */)
}

